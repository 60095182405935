<template>
  <div class="navigation-container">
    <div class="topnav-overlay-bg" v-if="levelOne" @click="removeNavigation"></div>  
    <div class="topnav" id="navigation" data-testid="navigation" @mouseleave="removeNavigation">
      <ul class="topnav-top-level">
        <li
          v-for="item in dataStructure"
          :key="item.id"
          @mouseenter="openSubNav(item)"
          class="topnav-item" 
          :class="topNavClass(levelOne, item)"
        >
          <a class="no-decoration" v-if="canHover" :href="item.url">{{item.name}}</a>
          <span v-else>{{item.name}}</span>
        </li>
      </ul>
      <transition name="fade">
        <div class="topnav-submenu" data-testid="submenu" v-if="showSubNav" :key="1">
          <div class="row topnav-row">
            <div class="column large-2 topnav-column" :class="levelOneActive && 'active'">
              <a
                class="topnav-h1"
                :href="levelOne.children[0].url"
                @click="trackNavItem(levelOne.children[0])"
                @mouseenter="addLevelOneActive(levelOne.children[0])"
              >
                {{levelOne.children[0].name}}
              </a>
            </div>
            <div class="column topnav-column" :class="levelOneColumnClass">
              <ul class="topnav-subnav-list" data-testid="subnav-list">
                <li                  
                  v-for="(item, i) in levelOneList"
                  class="subnav-item"
                  :class="itemClass(item, levelOneActive)"
                  @mouseenter="openLevelTwo(item, i)"
                  :style="{'animation-delay': (0.08*i) +'s'}"
                  :key="item.name">
                    <div
                      :class="item.weighting && ('topnav-' + item.weighting.toLowerCase())"
                      v-if="item.weighting || item.children">{{item.name}}
                    </div>
                    <a :href="item.url" v-else @click="trackNavItem(item)">{{item.name}}</a>
                </li>
              </ul>
            </div>
            <div class="column topnav-column" v-if="levelTwo" :class="levelTwoColumnClass" :style="{ 'top': levelTwoOffset + 'px' }">
              <ul class="topnav-subnav-list" data-testid="subnav-list">
                <li                  
                  v-for="(item, i) in levelTwoList"
                  class="subnav-item"
                  :class="itemClass(item, levelTwoActive)"
                  @mouseenter="openLevelThree(item, i)"
                  :style="{'animation-delay': (0.08*i) +'s'}"
                  :key="item.name"
                >
                  <div
                    :class="item.weighting && ('topnav-' + item.weighting.toLowerCase())"
                    v-if="item.weighting || item.children"
                  >
                    {{item.name}}
                  </div>
                  <a :href="item.url" v-else @click="trackNavItem(item)">{{item.name}}</a>
                </li>
              </ul>
            </div>
            <div class="column large-2 topnav-column" v-if="levelThree" :class="levelThreeColumnClass" :style="{ 'top': levelThreeOffset + 'px' }">
              <ul class="topnav-subnav-list" data-testid="subnav-list">
                <li
                  class="subnav-item"
                  v-for="(item, i) in levelThreeList"
                  :class="itemClass(item, levelThreeActive)"
                  @mouseenter="levelThreeHover(item)"
                  :style="{'animation-delay': (0.08*i) +'s'}"
                  :key="item.name"                
                >
                  <div
                    :class="item.weighting && ('topnav-' + item.weighting.toLowerCase())"
                    v-if="item.weighting || item.children">{{item.name}}
                  </div>
                  <a :href="item.url" v-else @click="trackNavItem(item)">{{item.name}}</a>
                </li>
              </ul>
            </div>
            <div class="column large-4 topnav-column image" :style="{'margin-left': 'auto'}">
              <img :src="image" v-if="image"/>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { postTrackEvent } from '../../utils/analytics';

export default {
  name: 'NavigationDesktop',
  data() {
    return {
      showSubNav: false,
      dataStructure: [],
      levelOne: null,
      levelTwo: null,
      levelThree: null,
      image: null,
      levelOneActive: false,
      levelTwoActive: false,
      levelTwoThree: false,
      levelThreeActive: false,
      levelTwoOffset: 0,
      levelThreeOffset: 0,
    };
  },
  created() {
    this.dataStructure = this.$root.structure.filter(item => !item.hide_on_desktop);    
    setTimeout(() => {
      const search = document.querySelector('.header__search');
      if (search) {
        document
          .querySelector('.header__search')
          .addEventListener('click', () => {
            this.removeNavigation();
          });
      }
    }, 1000);
  },
  computed: {
    canHover() {
      return window.matchMedia('(hover: hover)').matches;
    },
    topNavClass() {
      return (levelOne, item) => {
        const hasDecoration = item?.decoration !== 'none';
        const decorationClassName = hasDecoration ? `topnav-item--${item.decoration}` : '';
        return {
          active: levelOne === item,
          [decorationClassName]: true,
          "topnav-item--decoration": hasDecoration
        };
      };
    },
    levelOneList() {
      const levelOneList = this.levelOne.children[0].children;
      return levelOneList.filter(item => !item.hide_on_desktop);
    },
    levelOneColumnClass() {
      return {
        active: this.levelOneActive,
        'large-4': this.levelOne.children[0].length === 'long',
        'large-2': this.levelOne.children[0].length !== 'long',
      };
    },
    levelTwoList() {
      const levelTwoList = this.levelTwo.children;
      return levelTwoList.filter(item => !item.hide_on_desktop);     
    },
    levelTwoColumnClass() {
      return {
        active: this.levelTwoActive,
        'large-4': this.levelTwo.length === 'long',
        'large-2': this.levelTwo.length !== 'long',
      };
    },
    levelThreeList() {
      const levelThreeList = this.levelThree.children;
      return levelThreeList.filter(item => !item.hide_on_desktop);     
    },    
    levelThreeColumnClass() {
      return {
        active: this.levelThreeActive,
      };
    },
    itemClass() {
      return (item, levelActive) => {
        return {
          'has-subitems': item.children,
          'no-hover': item.weighting,
          active: levelActive === item,
        };
      };
    },
  },
  methods: {
    updateData() {
      this.dataStructure = this.$root.structure.filter(item => !item.hide_on_desktop);;
    },
    openSubNav(item) {
      if (!this.levelOne) {
        postTrackEvent('nav_open', { type: 'desktop' });
      }
      this.levelOne = item;
      this.levelTwo = null;
      this.levelThree = null;
      this.levelOneActive = false;
      this.levelTwoActive = false;
      this.levelTwoThree = false;
      this.showSubNav = true;
      if (item.children[0].image) this.image = item.children[0].image;
      else this.image = item.image;
    },
    addLevelOneActive(item) {
      this.levelOneActive = item;
    },
    openLevelTwo(item, i) {
      if (item.children) {
        this.levelTwo = item;
        // 34 is the height of list item
        this.levelTwoOffset = i * 34;
      } else {
        this.levelTwo = null;
        this.levelTwoOffset = 0;
        this.levelTwoActive = false;
      }

      if (item != this.levelThree) this.levelThree = null;
      this.levelOneActive = item;

      if (item.image) {
        this.image = item.image;
      } else {
        this.updateLevelOneImage();
      }
    },
    openLevelThree(item, i) {
      if (item.children) {
        this.levelThree = item;
        // 34 is the height of list item
        this.levelThreeOffset = parseInt(this.levelTwoOffset) + i * 34;
      } else {
        this.levelThree = null;
        this.levelThreeOffset = 0;
        this.levelThreeActive = false;
      }

      this.levelTwoActive = item;

      if (item.image) {
        this.image = item.image;
      } else {
        this.updateLevelTwoImage();
      }
    },
    levelThreeHover(item) {
      this.levelThreeActive = item;
      if (item.image) {
        this.image = item.image;
      } else if (this.levelThree.image) {
        this.image = this.levelThree.image;
      } else {
        this.updateLevelTwoImage();
      }
    },
    updateLevelOneImage() {
      if (this.levelOne?.children[0].image) {
        this.image = this.levelOne.children[0].image;
      } else {
        this.image = this.levelOne?.image;
      }
    },
    updateLevelTwoImage() {
      if (this.levelTwo.image) {
        this.image = this.levelTwo.image;
      } else {
        this.updateLevelOneImage();
      }
    },
    removeNavigation() {
      this.levelOne = null;
      this.levelTwo = null;
      this.levelThree = null;
      this.levelOneActive = false;
      this.levelTwoActive = false;
      this.levelTwoThree = false;
      this.image = null;
      this.showSubNav = false;

      postTrackEvent('nav_close', { type: 'desktop' });
    },
    trackNavItem(item) {
      postTrackEvent('nav_click_item', {
        position: 'header',
        name: item.name,
        link: item.url,
        type: 'desktop',
      });
    },
  },
};
</script>
