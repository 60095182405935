<template lang="pug">
  div
    async-sidebar(
          :async-component="Microcart"
          :is-open="isMicrocartOpen"
          @close="$store.commit('ui/setMicrocart', false)"
        )
</template>
<script>
import config from 'config';
import { mapState, mapGetters } from 'vuex';
import AsyncSidebar from './components/theme/blocks/AsyncSidebar/AsyncSidebar.vue';
import Loader from './components/core/Loader.vue';
import store from './store';
import { CART_LOAD_CART_SERVER_TOKEN } from '../core/modules/cart/store/mutation-types';
import { USER_TOKEN_CHANGED } from '../core/modules/user/store/mutation-types';
import { cartSyncHandler } from '../core/modules/cart/helpers';
import postMessage from '../utils/postMessage';
import { postTrackEvent } from '../utils/analytics';

const Microcart = () => import('./components/core/blocks/Microcart/Microcart.vue');

export default {
  name: 'CartWrapper',
  store,
  components: {
    AsyncSidebar,
    Loader,
    Microcart
  },
  props: {},
  data() {
    return {
      Microcart,
    };
  },
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    }),
    ...mapGetters({
      showZendesk: 'ui/getZendeskWidgetVisibility',
      offset: 'ui/getZendeskWidgetOffset',
    })
  },
  watch: {
    showZendesk: {
      immediate: true,
      deep: true,
      handler(ov, nv) {
        // whenever showZendesk is changed in the store - this is an indicator if we
        // should be showing or hiding the zendesk widget
        if (window.zE && nv !== undefined) {
          if (nv === true) {
            window.zE.hide();
          } else {
            window.zE('webWidget', 'updateSettings', {
              offset: this.offset,
            });
            window.zE.show();
          }
        }
      }
    }
  },
  methods: {
    addUserToken () {
      // This will get the session token from the cookie and attach it to the user
      // token element.
      const token = document.querySelector('[name=csrfmiddlewaretoken]')?.value;
      if (token) {
        this.$store.commit('user/' + USER_TOKEN_CHANGED, {
          newToken: token
        });
      }
    },
    postAnalyticsEvent(product, location) {
      // This will send the information to Analytics using the front end code
      // Mainly for Facebook
      const currency = window.currentCurrency[0];
      const cartId = this.$store.getters['cart/getCartToken'];
      this.$store.dispatch('product/single', {
        options: {
          sku: product.sku
        }
      }).then((resp) => {
        postTrackEvent('Product Added', {
          cart_id: cartId,
          currency: currency,
          price: resp.price,
          product_id: product.sku,
          quantity: product.quantity,
          sku: product.sku,
          add_type: location,
          url: window.location.pathname
        });
      });
    },
    async changeCartItemFromMessage (msg) {
      // This gets called when there is a cart addToBasket event. It takes the
      // event data and passes it to the store mutator.
      if (msg.data && msg.data.type !== 'cart') { return; }
      if (msg.data.data === undefined) { return; }

      const data = msg.data.data.products;
      const productsForConfirmationToast = [];
      let productsToSync = [];

      for (const p of data) {
        const d = {
          sku: p.sku,
          qty: p.quantity,
          name: p.name,
        };
        if (p.method === 'add') {
          const product = await this.$store.dispatch('product/single', {
            options: {
              sku: p.sku
            }
          });
          const qty = d.qty > product.max_sale_qty ? product.max_sale_qty : d.qty
          product.qty = qty;
          product.qty_requested = d.qty;
          if (product.type_id === 'grouped') { // note: add bundle support
            productsToSync += product.product_links
              .filter(productLink => productLink.link_type === 'associated')
              .map(productLink => productLink.product);
          } else {
            productsToSync.push(product);
          }
          productsForConfirmationToast.push({
            qty: qty,
            name: d.name,
          });
          this.postAnalyticsEvent(p, p.location || msg.data.data.location);
        } else if (p.method === 'replace' && d.qty > 0) {
          d.server_item_id = p.variant;
          this.$store.dispatch('cart/updateQuantity', { product: d, qty: d.qty });
        } else {
          const cartItem = this.$store.getters['cart/getCartItems'].find(pr => pr.sku === p.sku);
          this.$store.dispatch('cart/removeItem', { product: cartItem });
        }
      }
      if (productsToSync.length) {
        this.$store.dispatch('cart/addItems', { productsToAdd: productsToSync });
      }

      if (productsForConfirmationToast.length) {
        const itemAddedMsg = window.PATCH_I18N.cart.product_added;
        postMessage('message', {
          data: {
            functionName: 'addSuccess',
            param: productsForConfirmationToast.map(prod => `${prod.qty} x ${prod.name} ${itemAddedMsg}`)
          }
        });
      }
    },
    addCartToken() {
      let token;
      const localStorageBasket = localStorage.getItem(config.cart.basket_change_key);
      if (localStorageBasket) {
        token = JSON.parse(localStorageBasket).cartToken;
        this.$store.commit('cart/' + CART_LOAD_CART_SERVER_TOKEN, token);
      }
      this.$store.dispatch('cart/fetchInitialCartData').then((cartData) => {
        const cartItems = cartData.cartItems;
        if (!token) {
          this.$store.commit('cart/' + CART_LOAD_CART_SERVER_TOKEN, cartData.cartServerToken);
        }

        const cartIconWrapper = document.querySelector('#cartIconWrapper');
        if (cartIconWrapper) {
          cartIconWrapper.setAttribute('data-cartBootstrap', JSON.stringify({ token: cartData.cartServerToken, items: cartItems }));
        }
        this.initialiseCart();
      });
    },
    initialiseCart() {
      this.$store.dispatch('cart/sync', {
        forceClientState: false,
        dryRun: false
      }).then(() => {
        const locationHref = window.location.href;
        if (locationHref.endsWith('?cart=open')) {
          this.$store.commit('ui/setMicrocart', true);
        }
      });
    },
    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
    setupCartSync() {
      cartSyncHandler.addEventListener();

      // Handle any syncing required on pageshow events
      window.onpageshow = event => {
        // If page was loaded from cache (eg: some browsers render a cached page when coming from back/forward button),
        // then the basket data will not be up to date, so lets trigger a sync.
        if (event.persisted) {
          cartSyncHandler.triggerBasketChange(true);
        }
      };
    },
  },
  mounted() {
    this.addUserToken();
    this.addCartToken();
    this.setupCartSync();
    window.addEventListener('message', this.changeCartItemFromMessage);
  },
  destroyed() {
    window.removeEventListener('message', this.handleScroll);
  },
};
</script>
